export interface ServiceDetail { 
  contract: string
  walletLink: string
}

export const SERVICE: { [key: string]: ServiceDetail } = {
 'primo': {
   contract: '0xA4641F7f714135777FBDEAc89c4dE9292027De64',
   walletLink: 'https://pcoin-wallet.pages.dev/sign-payment/'
 },
 's2m': {
  contract: '0xad174095185200fa77e5Bb53771491FF84E48f1E',
  walletLink: 'https://wallet.s2mcoin.com/sign-payment/'
 }
}