import { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router";
import styled from "styled-components";
import { SERVICE, ServiceDetail } from "../constants";
import { BigNumber, ethers } from "ethers";
import Logo from "../assets/tokenine_logo_white.svg";

interface ParamQR {
  type: string;
  service: string;
  orderId: string;
  payTo: string;
  amount: string;
  token: string;
  payContract: string;
  newTab: boolean;
  hideqr: boolean;
}

const HomeContainer = styled.div`
  max-width: 500px; //960px
  margin: 0 auto;
  border-radius: 12px;
  padding: 25px;
  background-color: white;
  margin-bottom: 25px;
  @media only screen and (max-width: 600px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 700px) {
    max-width: 250px;
  }
`;

const LogoSize = styled.img`
  width: 580px; //960px
  @media only screen and (max-width: 600px) {
    width: 380px;
  }
  @media only screen and (max-width: 700px) {
    width: 320px;
  }
`;

const ShowQrcode = styled.div`
  max-height: ${(props: { isShow: boolean }) =>
    props.isShow ? "250px" : "0px"};
  transition: max-height 0.5s ease-in;
  overflow: hidden;
`;

const Button = styled.button`
  border: 2px solid #3db2ff; //#0067b6
  background-color: white;
  color: #3db2ff;
  padding: 14px 58px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
  min-width: 250px;
  @media only screen and (max-width: 700px) {
    min-width: 200px;
  }
`;

const Card = styled.div`
  border: 2px solid #3db2ff;
  border-radius: 8px;
  max-width: 350px;
  margin: 0 auto;
  margin-top: 10px;
`;

const shortenAddress = (address: string) => {
  return (
    address.substring(0, 6) +
    "..." +
    address.substring(address.length - 4, address.length)
  );
};

const symbolAbi = [
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
];
const orderIDAbi = ["function isPaid(uint256) view returns (bool)"];

const Home = () => {
  const location = useLocation();

  const [paramQR, setParamQR] = useState<ParamQR>();
  const [showQRcode, setShowQRcode] = useState(false);
  const [serviceDetail, setServiceDetail] = useState<ServiceDetail>();
  const [isCorrect, setIsCorrect] = useState(false);
  const [warningError, setWarningError] = useState("");

  const [tokenSymbol, setTokenSymbol] = useState("");

  const [isLoadingCheckOrderId, setIsLoadingCheckOrderId] = useState(true);
  const [isLoadingToken, setIsLoadingToken] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orderId = query.get("order_id");
    const payTo = query.get("to");
    const amount = query.get("amount");
    const token = query.get("token");
    const newtab = query.get("new_tab");
    const hideqr = query.get("hide_qr")

    const sparam = query.get("service") ?? "primo";
    const service = SERVICE[sparam];
    setServiceDetail(service);

    const getTokenDetail = async () => {
      try {
        if (!token) return;
        const contract = new ethers.Contract(
          token,
          symbolAbi,
          ethers.getDefaultProvider("https://rpc.tbwg.io")
        );
        const symbol = await contract.symbol();
        setIsLoadingToken(false);
        setTokenSymbol(symbol);
      } catch (err) {
        console.error(err);
        setIsLoadingToken(false);
      }
    };

    const getCheckOriderid = async () => {
      try {
        const contract = new ethers.Contract(
          service.contract,
          orderIDAbi,
          ethers.getDefaultProvider("https://rpc.tbwg.io")
        );
        const bigOrderId = ethers.BigNumber.from(orderId);
        const checked = await contract.isPaid(bigOrderId);
        setIsLoadingCheckOrderId(false);
        if (checked) {
          setWarningError("This order ID already used");
        }
      } catch (err) {
        console.error(err);
        setIsLoadingCheckOrderId(false);
      }
    };

    const checkAddress = (address: string | null) => {
      if (address !== null) {
        if (!ethers.utils.isAddress(address)) {
          setWarningError("Incorrect address");
        }
      } else {
        setWarningError("Address is empty");
      }
    };

    const isCorrect = !!orderId && !!payTo && !!amount && !!token && !!service;
    if (warningError === "") {
      setIsCorrect(isCorrect);
      if (isCorrect) {
        checkAddress(payTo);
        checkAddress(token);
        getCheckOriderid();
        getTokenDetail();
        setParamQR({
          type: "sign_payment",
          service: sparam,
          orderId: orderId ?? "",
          payTo: payTo ?? "",
          amount: amount ?? "",
          token: token ?? "",
          payContract: service.contract,
          newTab: newtab !== "false",
          hideqr: hideqr === "true",
        });
      } else {
        setWarningError("Missing data");
      }
    }
    setIsLoadingToken(false);
    setIsLoadingCheckOrderId(false);
  }, [location, warningError]);

  return (
    <div>
      <LogoSize
        className="logo"
        style={{
          backgroundColor: "#3db2ff",
          marginBottom: "15px",
          maxWidth: "580px",
        }}
        src={Logo}
      />
      <HomeContainer>
        <h1
          style={{
            textAlign: "center",
            fontStyle: "normal",
            marginTop: "0px",
          }}
        >
          PAYMENT
        </h1>
        {(isLoadingCheckOrderId || isLoadingToken) && (
          <div>
            <div style={{ fontSize: "19px", textAlign: "center" }}>
              Loading...
            </div>
          </div>
        )}
        {warningError && !isLoadingCheckOrderId && (
          <div style={{ fontSize: "30px", textAlign: "center" }}>
            {" "}
            {warningError}{" "}
          </div>
        )}
        {!warningError &&
          isCorrect &&
          !isLoadingCheckOrderId &&
          !isLoadingToken && (
            <div style={{ paddingBottom: "25px" }}>
              {paramQR && (
                <Card>
                  <div style={{ padding: "10px" }}>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>Service</td>
                          <td style={{ textAlign: "right" }}>
                            {paramQR?.service}
                          </td>
                        </tr>
                        <tr>
                          <td>Order ID</td>
                          <td style={{ textAlign: "right" }}>
                            {paramQR?.orderId}
                          </td>
                        </tr>
                        <tr>
                          <td>Amount</td>
                          <td style={{ textAlign: "right" }}>
                            {ethers.utils.formatUnits(
                              BigNumber.from(paramQR.amount),
                              BigNumber.from(18)
                            )}{" "}
                            {tokenSymbol}
                          </td>
                        </tr>
                        <tr>
                          <td>Pay to</td>
                          <td style={{ textAlign: "right" }}>
                            {shortenAddress(paramQR?.payTo ?? "")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              )}
              <ShowQrcode
                isShow={!!paramQR && showQRcode}
                style={{ textAlign: "center", marginTop: "25px" }}
              >
                <QRCode size={180} value={JSON.stringify(paramQR)} />
              </ShowQrcode>
              <div>
                <div style={{ textAlign: "center", marginTop: "25px" }}>
                  {!paramQR?.hideqr && <Button
                    onClick={() => {
                      setShowQRcode(!showQRcode);
                    }}
                  >
                    {showQRcode && "Close"} QR code
                  </Button>}
                </div>
                <div style={{ textAlign: "center", marginTop: "25px" }}>
                  <a
                    rel="noreferrer"
                    href={`${serviceDetail?.walletLink}?params=${paramQR?.payTo},${paramQR?.orderId},${paramQR?.amount},${paramQR?.token},${paramQR?.payContract}`}
                    target={paramQR?.newTab ? "_blank" : "_self"}
                  >
                    <Button
                      style={{ backgroundColor: "#3db2ff", color: "white" }}
                    >
                      Proceed to payment
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          )}
      </HomeContainer>
    </div>
  );
};

export default Home;
